(function () {
    window.dataLayer = window.dataLayer || [];

    var AtobUtf8 = function () {
        var revLookup = [];
        var Arr = typeof Uint8Array !== "undefined" ? Uint8Array : Array;

        var code =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        for (var i = 0, len = code.length; i < len; ++i) {
            revLookup[code.charCodeAt(i)] = i;
        }

        // Support decoding URL-safe base64 strings, as Node.js does.
        // See: https://en.wikipedia.org/wiki/Base64#URL_applications
        revLookup["-".charCodeAt(0)] = 62;
        revLookup["_".charCodeAt(0)] = 63;

        function getLens(b64) {
            var len = b64.length;

            if (len % 4 > 0) {
                throw new Error(
                    "Invalid string. Length must be a multiple of 4"
                );
            }

            // Trim off extra bytes after placeholder bytes are found
            // See: https://github.com/beatgammit/base64-js/issues/42
            var validLen = b64.indexOf("=");
            if (validLen === -1) {
                validLen = len;
            }

            var placeHoldersLen = validLen === len ? 0 : 4 - (validLen % 4);

            return [validLen, placeHoldersLen];
        }

        function _byteLength(b64, validLen, placeHoldersLen) {
            return ((validLen + placeHoldersLen) * 3) / 4 - placeHoldersLen;
        }

        function toByteArray(b64) {
            var tmp;
            var lens = getLens(b64);
            var validLen = lens[0];
            var placeHoldersLen = lens[1];

            var arr = new Arr(_byteLength(b64, validLen, placeHoldersLen));

            var curByte = 0;

            // if there are placeholders, only get up to the last complete 4 chars
            var len = placeHoldersLen > 0 ? validLen - 4 : validLen;

            for (var i = 0; i < len; i += 4) {
                tmp =
                    (revLookup[b64.charCodeAt(i)] << 18) |
                    (revLookup[b64.charCodeAt(i + 1)] << 12) |
                    (revLookup[b64.charCodeAt(i + 2)] << 6) |
                    revLookup[b64.charCodeAt(i + 3)];
                arr[curByte++] = (tmp >> 16) & 0xff;
                arr[curByte++] = (tmp >> 8) & 0xff;
                arr[curByte++] = tmp & 0xff;
            }

            if (placeHoldersLen === 2) {
                tmp =
                    (revLookup[b64.charCodeAt(i)] << 2) |
                    (revLookup[b64.charCodeAt(i + 1)] >> 4);
                arr[curByte++] = tmp & 0xff;
            }

            if (placeHoldersLen === 1) {
                tmp =
                    (revLookup[b64.charCodeAt(i)] << 10) |
                    (revLookup[b64.charCodeAt(i + 1)] << 4) |
                    (revLookup[b64.charCodeAt(i + 2)] >> 2);
                arr[curByte++] = (tmp >> 8) & 0xff;
                arr[curByte++] = tmp & 0xff;
            }

            return arr;
        }

        return {
            toByteArray: toByteArray,
        };
    };
    var base64js = new AtobUtf8();

    var TextDecoderLite = function () {
        function TextDecoderLite() {}

        TextDecoderLite.prototype.decode = function (bytes) {
            return utf8Slice(bytes, 0, bytes.length);
        };

        function utf8Slice(buf, start, end) {
            var res = "";
            var tmp = "";
            end = Math.min(buf.length, end || Infinity);
            start = start || 0;

            for (var i = start; i < end; i++) {
                if (buf[i] <= 0x7f) {
                    res += decodeUtf8Char(tmp) + String.fromCharCode(buf[i]);
                    tmp = "";
                } else {
                    tmp += "%" + buf[i].toString(16);
                }
            }

            return res + decodeUtf8Char(tmp);
        }

        function decodeUtf8Char(str) {
            try {
                return decodeURIComponent(str);
            } catch (err) {
                return String.fromCharCode(0xfffd); // UTF 8 invalid char
            }
        }

        return TextDecoderLite;
    };
    var decoder = new (window.TextDecoder || TextDecoderLite)("utf-8");

    function getJwtTokenAsObject(cookieName) {
        function b64DecodeUnicode(str) {
            var bytes = base64js.toByteArray(str);
            return decoder.decode(bytes);
        }

        function rightPad(str) {
            switch (str.length % 4) {
                case 1:
                    return str + "===";
                case 2:
                    return str + "==";
                case 3:
                    return str + "=";
                default:
                    return str;
            }
        }

        function getCookieValue(a) {
            var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
            return b ? b.pop() : "";
        }

        var cookie = getCookieValue(cookieName);
        if (cookie === "") {
            return {};
        }

        try {
            var base64Url = cookie.split(".")[1];
            var base64 = base64Url.replace("-", "+").replace("_", "/");
            return JSON.parse(b64DecodeUnicode(rightPad(base64)));
        } catch (err) {
            return {};
        }
    }

    function getCustomerType(customerGroup) {
        var customerGroupPrivate = 40;
        var customerGroupEmployee = 50;
        var isBusinessCustomer =
            customerGroup !== undefined &&
            customerGroup != customerGroupPrivate &&
            customerGroup != customerGroupEmployee;

        return isBusinessCustomer ? "Commercial" : "Private";
    }

    async function hashString(message) {
        if (message) {
            const msgUint8 = new TextEncoder().encode(message);
            const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            hashHex = hashArray
                .map((b) => b.toString(16).padStart(2, "0"))
                .join("");

            return hashHex;
        }
    }

    async function init() {
        var cookieData = getJwtTokenAsObject("Komplett.Caas.Session");

        var akamaiCachingOn = !(window.dataLayer[0] || {}).customerType;
        if (akamaiCachingOn) {
            var pageData = window.dataLayer.find((x) => x.environment);
            var paths = location.pathname.split("/");

            window.dataLayer.push({
                event: "page_data",
                page_type:
                    ((paths.length > 1 && paths[1]) || "front") + " page",
                customer_type: getCustomerType(cookieData.cmg),
                customer_category: undefined,
                user_id: await hashString(cookieData.kid || undefined),
                profile_email: cookieData.usn ? cookieData.usn : undefined,
                environment: pageData && pageData.environment,
                loyalty_member: cookieData.kc ? true : false,
                credit_member: false,
                insurance_member: false,
                freight_service_member: false,
            });
        }

        loadGtmScript(
            window.GTM.GoogleTagManagerId,
            window.GTM.GoogleTagManagerHost
        );
    }

    function loadGtmScript(gtmId, gtmHost) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
        });
        var siblingTag = document.getElementsByTagName("script")[0],
            scriptTag = document.createElement("script");

        scriptTag.async = true;
        scriptTag.src = `https://${gtmHost}/gtm.js?id=${gtmId}`;

        siblingTag.parentNode.insertBefore(scriptTag, siblingTag);
    }

    init();
})();
